<template>
  <div>
    <gql-table
      ref="countryTable"
      :query="query"
      :fields="columns"
      query-type="countries"
      sort-by="name"
      :sort-desc="false"
    >
      <template #cell(name)="data">
        <b-link :to="{name: 'settings-country-detail', params: {id: data.item.id}}">
          {{ data.item.name }}
        </b-link>
      </template>
    </gql-table>
  </div>
</template>
<script>

import { BLink } from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'

export default {
  components: {
    GqlTable, BLink,
  },
  data() {
    return {
      columns: [
        {
          name: 'code2', label: 'Country code', visible: true,
        },
        {
          name: 'name', label: 'Country name', filterable: 'like', visible: true, sortable: true,
        },
      ],
      query: [
        'id',
        'name',
        'code2',
      ],
    }
  },

}
</script>
